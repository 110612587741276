import React, {useState} from "react"
import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import { Link, graphql } from "gatsby"
import logo from "../images/kenny1.png"
// import Logo from "./logo"
import { Grid, Box, Button, jsx } from 'theme-ui'
import Img from "gatsby-image/withIEPolyfill"
import kennydnface from '../images/ken.jpg'
import subscribe from '../images/subscribe.png'
import Slide from 'react-reveal/Slide';
import addToMailchimp from 'gatsby-plugin-mailchimp'
import swal from 'sweetalert';
import '../styles/index.css';

function IndexPage({data}) {
  const about = data.allWordpressPage.edges[0].node
  console.log(about.content)
  const current_book = data.allWordpressWpBooks.edges[0].node
  const [formState, setFormState] = useState({ name:"", email: ""});

  function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&")
  }


  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  const handleSubmit = (event) => {
  event.preventDefault()
  if (formState.name == "" || formState.email == "" || formState.title == "" 
  || formState.name == null || formState.email == null || formState == null ){
    swal("Cancelled", "Please fill in the form", "error")
    }else{
      fetch("/", {
        method: "POST",
        headers: {"Content-Type": "application/x-www-form-urlencoded"},
        body: encode({ 'form-name': "subscriptionLetter", ...formState})
        }).then(() => swal("Good job!", "You are now a NightSky subscriber. You will receive your first letter in a few days as an email with an attachment. - Kenny", "success")).catch(error => alert(error))
      }
  }
  return(
  <Layout>
    <form  name="subscriptionLetter" data-netlify="true" hidden>
    <input type="text" name="name" />
    <input type="email" name="email" />
    </form>
  <SEO title="blogs" />
    {/* <Logo/> */}
    <img src={logo} style={{marginBottom: "0.5rem"}}/>
  <Box bg='primary' >
  <div style={{padding: "4vw", boxShadow: "5px 10px"}} dangerouslySetInnerHTML={{ __html: about.content }} />
  </Box>
  <Slide bottom>

  <Grid width={[100,null,100 ]} className="subscription-box" >
  <Box>
    <img src={kennydnface} />
  </Box>
  <Box style={{display: "block", textAlign:"center", padding: "10%"}}>
  {/* <img src={subscribe} /> */}
  <h3>NightSky is a free periodic publications the showcases our 'stuff'. I will send it to you by email
    time to time</h3>
  <p>I promise there will be no spam</p>
    <form  method="post" name="subscriptionLetter" onSubmit={handleSubmit} 
    style={{textAlign:"center"}}>
    <input type="hidden" name="bot-field" />
    <input type="hidden" name="subscriptionLetter" value="subscriptionLetter" />
      <p>
      <input className="subscription-input" placeholder="your name"  type="text" name="name" value={formState.name} onChange={handleChange}/>
      </p>
      <p>
      <input className="subscription-input" placeholder="email"  type="email" name="email" value={formState.email} onChange={handleChange}/>
      </p>
      <p>
        <Button type="submit">Subscribe</Button>
      </p>
      </form>
  </Box>
  </Grid>
  </Slide>
</Layout>
  )
}

export default IndexPage
export const query = graphql`
  query {
    allWordpressPage {
      edges {
        node {
          title
          excerpt
          content
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth:350, quality:100){
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
  
                }
                resolutions(width:350, height: 500) {
                  ...GatsbyImageSharpResolutions_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
    allWordpressWpBooks {
      edges {
        node {
          title
          excerpt
          slug
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth:350, quality:100){
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
  
                }
                resolutions(width:350, height: 500) {
                  ...GatsbyImageSharpResolutions_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`
